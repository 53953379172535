import "./style.scss";

import domReady from "@wordpress/dom-ready";

function ContactForm() {
  const forms = document.querySelectorAll("form.contact-form");
  if (!forms.length) return null;

  forms.forEach(function (form) {
    //Fetch submit btn
    const submitBtn = form.querySelectorAll(".submit")[0];

    //Fetch error message field
    const error_message = form.querySelectorAll(".error-message")[0];

    //Fetch Succes message field
    const succes_message = form.querySelectorAll(".succes-message")[0];

    //Fetch all required fields
    const requiredFields = form.querySelectorAll("[required]");

    //Setup validation
    requiredFields.forEach(function (input, index) {
      input.addEventListener("keyup", (event) => {
        if (input.classList.contains("error-input")) {
          input.classList.remove("error-input");
          error_message.classList.remove("show");
        } else if (input.value.length > 0) {
          input.classList.add("succes-input");
        }

        return false;
      });
    });

    //Listen for submit of contact form
    submitBtn.addEventListener("click", () => {
      let count = 0;
      let emailInput = form.querySelector("#email");

      requiredFields.forEach(function (input, index) {
        if (input.value.trim().length <= 0) {
          input.classList.add("error-input");
          count++;
        }
      });

      if (validateEmail(emailInput.value) == null) {
        emailInput.classList.add("error-input");
        count++;
      }

      //If count is > 0 means there is missing fields
      if (count > 0) {
        error_message.classList.add("show");
        return false;
      }

      //If we are here all is good to be submittet.

      //Buld formdata
      var formData = new FormData(form);

      //Apend distanation
      formData.append("action", "contact_form");

      var xhttp = new XMLHttpRequest();
      xhttp.open(
        "POST",
        window.location.protocol +
          "//" +
          window.location.hostname +
          "/wp-admin/admin-ajax.php"
      );
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          var response = this.responseText;
          var data = JSON.parse(response);

          if (data.status === 200) {
            succes_message.classList.toggle("show");
            //reset succes fields
            const resetFields = form.querySelectorAll(".succes-input");
            resetFields.forEach(function (input, index) {
              input.classList.remove("succes-input");
            });
            setTimeout(() => {
              succes_message.classList.toggle("show");
            }, 5000);
            form.reset();
          } else {
            error_message.classList.add("show");
            setTimeout(() => {
              error_message.classList.toggle("show");
            }, 2000);
          }

          xhttp.abort();
        }
      };

      xhttp.send(formData);

      if (window["dataLayer"] && window["dataLayer"].push) {
        window["dataLayer"].push({
          event: "Kontaktformular",
          email: emailInput?.value,
        });
      }

      return false;
    });
  });
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

domReady(ContactForm);
